/*
 * @Author       : JiangChao
 * @Date         : 2024-08-16 21:52:24
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-10-23 15:05:31
 * @Description  :
 */
export const RegexUtils = {
  checkMobile(s) {
    let reg = /^1[0-9]{10}$/;
    let re = new RegExp(reg);
    if (re.test(s)) {
      return true;
    } else {
      return false;
    }
  },

  isEmail(str) {
    let myReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (myReg.test(str)) return true;
    return false;
  },

  checkPsw(str) {
    let myReg = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;
    if (myReg.test(str)) return true;
    return false;
  },
};
