/*
 * @Author       : JiangChao
 * @Date         : 2024-04-16 13:33:29
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-04-16 14:57:32
 * @Description  : 
 */
import { JSEncrypt } from 'jsencrypt'

let public_key = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgtADeif4NPELQUoaU2KT0d2suH665WqXpkVeG/6quOUx8TduX2aEunxgVk8Yz3OG/iTDiMD4z3+k+Ka0qMAVzyQuEEeqy3l/87fr7uxXqcilb73fbSB4IHymatApdnMVI/BBDRGzOxa38QkG3PgA2T/D369bemfLQVL/S0BZw6wIDAQAB";
let encrypter = new JSEncrypt();

export function encrypt(content) {
  encrypter.setPublicKey(public_key);
  return encrypter.encrypt(content);
}

